/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBreakdownRating = /* GraphQL */ `
  mutation CreateBreakdownRating(
    $input: CreateBreakdownRatingInput!
    $condition: ModelBreakdownRatingConditionInput
  ) {
    createBreakdownRating(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      name
      value
      notes
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBreakdownRating = /* GraphQL */ `
  mutation UpdateBreakdownRating(
    $input: UpdateBreakdownRatingInput!
    $condition: ModelBreakdownRatingConditionInput
  ) {
    updateBreakdownRating(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      name
      value
      notes
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBreakdownRating = /* GraphQL */ `
  mutation DeleteBreakdownRating(
    $input: DeleteBreakdownRatingInput!
    $condition: ModelBreakdownRatingConditionInput
  ) {
    deleteBreakdownRating(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      name
      value
      notes
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      type
      title
      yelpBusinessId
      yelpBusinessName
      restaurantName
      imgKeys
      description
      overall
      breakdownRatings {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          name
          value
          notes
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      likes {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      type
      title
      yelpBusinessId
      yelpBusinessName
      restaurantName
      imgKeys
      description
      overall
      breakdownRatings {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          name
          value
          notes
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      likes {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      type
      title
      yelpBusinessId
      yelpBusinessName
      restaurantName
      imgKeys
      description
      overall
      breakdownRatings {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          name
          value
          notes
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      likes {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
