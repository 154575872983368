import { Auth } from 'aws-amplify';

export async function signUp(email, username, password, attributes={}) {
    // attributes are optional extra fields (email, phone_number)
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                email, 
                ...attributes
            }
        });
        return user
    } catch (error) {
        console.log('error signing up:', error);
    }
}

async function confirmSignUp(username, code) {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
        console.log('error confirming sign up', error);
    }
}

export async function updateUser(attributes) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
}

// export async function signIn(username, password) {
//     const user = await Auth.signIn(username, password);
//     return user
// }

export async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

export async function forgotPasswordRequest(username) {
    // Send confirmation code to user's email
    Auth.forgotPassword(username)
        .then(data => console.log(data))
        .catch(err => console.log(err));
}

export async function resolveForgottenPassword(username, code, new_password) {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, new_password)
        .then(data => console.log(data))
        .catch(err => console.log(err));

}

export async function changePassword(oldPassword, newPassword) {
    Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(data => console.log(data))
        .catch(err => console.log(err));
}