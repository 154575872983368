import React, { useEffect, useState } from 'react'
// import { API, graphqlOperation } from 'aws-amplify';
import RatingFeedRating from './ratingfeedrating';
import { useQuery, gql } from '@apollo/client';
import { getRatingsByDate } from '../../graphql/queries';
import RMPLoader from '../rmp/rmploader';
import { useUser } from '../../helpers/user';
import { useRouteMatch, useHistory, useLocation } from 'react-router';
import * as qs from 'qs'

export default function RatingFeed({}) {
    const { url: baseRoute, ...rest } = useRouteMatch()
    const { search } = useLocation()
    const expanded = qs.parse(search, { ignoreQueryPrefix: true }).id
    const history = useHistory()

    console.log(expanded)
    const { data, loading, error } = useQuery(gql(getRatingsByDate), {
        variables: { type: "Rating", sortDirection: 'DESC' }
    })
    console.log(data)
    const {user} = useUser()

    if (loading) return <RMPLoader/>
    if (error) return "Error"

    if (!data) {
        return <>Loading...</>
    }
    const items = data.getRatingsByDate.items

    const handleSetExpanded = (id, shouldExpand) => {
        if (!shouldExpand) {
            history.push({
                pathname: baseRoute,
                search: ''
            })
        } else {
            history.push({
                pathname: baseRoute,
                search: `?id=${id}`
            })
        }
    }
    return <>
        {items.map(r => <RatingFeedRating 
            key={r.id} 
            rating={r} 
            expand={expanded == r.id}
            setExpand={shouldExpand => handleSetExpanded(r.id, shouldExpand)}
        />)}
    </>
}