import React from "react"
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

// import client from "./apollo/client"

import Login from "./components/Auth/login";
import Logout from "./components/Auth/logout";
import PrivateRoute from "./components/Auth/privateroute";

import './css/app.css'

// import MainLayout from "./components/Layout/mainlayout";
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import MainRouter from "./components/MainRouter";

import config from "./aws-exports";
import { Storage } from "aws-amplify";
// import AWSAppSyncClient from 'aws-appsync';

import client from "./apollo/client"

Storage.configure({
    region: config.aws_user_files_s3_bucket_region,
    bucket: config.aws_user_files_s3_bucket,
    identityPoolId: config.aws_user_pools_id,
    level: "protected",
});


function App() {
    return <ApolloProvider client={client}>
        <Router>
            <Switch>
                <Route path={'/login'} component={Login}/>
                <Route exact path={'/logout'} component={Logout} />
                <PrivateRoute
                    path=''
                    render={() => <MainRouter/>}
                />
            </Switch>
        </Router>
    </ApolloProvider>
}

export default App
// export default withAuthenticator(App)