import API from "@aws-amplify/api";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import React, { useState, useEffect } from "react";
import Select from "react-select"
import {components} from "react-select"
import { yelpBusinessSearchWithLocation } from "../../graphql-custom/queries";
import useDebounce from "../rmp/usedebounce"
import { rmpToastError } from "../RMPToasts/rmptoasts";

const Option = props => {
    return (
        <components.Option {...props}>
            <div style={{fontSize: "0.8em"}}>
                {props.data.label}
                <div>
                    <small>{props.data.subLabel}</small>
                </div>
            </div>
        </components.Option>
    );
};

export default function YelpBusinessSearch({
    placeholder,
    setYelpBusinessId,
    setYelpBusinessName
}) {
    const [selected, setSelected] = useState(null)
    const [results, setResults] = useState([])
    const [term, setTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const debouncedTerm = useDebounce(term, 300)
    const [position, setPosition] = useState(null)

    async function searchBusinesses(term, limit=20) {
        if (!position) {
            console.error("Position not loaded, can't query Yelp")

            rmpToastError('Restaurant search broken, can\'t find your location')
        }
        try {
            setLoading(true)
            var data = await API.graphql(graphqlOperation(yelpBusinessSearchWithLocation, {
                query: {
                    term: term,
                    latitude: position.latitude,
                    longitude: position.longitude,
                    limit
                }
            }))
        } catch (err) { 
            setLoading(false)
            console.log('error fetching', err) 
            return
        }
        setLoading(false)
        const res = data.data.yelpBusinessSearch
        return res.businesses    
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(geoPos => {
            setPosition({
                latitude: geoPos.coords.latitude, 
                longitude: geoPos.coords.longitude
            })
            console.log(geoPos)
        }, err => console.log(err))
    }, [])

    useEffect(() => {
        if (debouncedTerm.length > 0) {
            console.log('querying')
            searchBusinesses(debouncedTerm).then(res => setResults(res))
        }
    }, [debouncedTerm])


    const handleChange = val => {
        setSelected(val)
        setYelpBusinessId(val.value)
        setYelpBusinessName(val.label)
    }

    const options = (term.length > 0 && !loading && !!results) ? results.map(r => {
        const ret = {
            label: r.name,
            value: r.id,
            subLabel: `${r.location.city}, ${r.location.state}`
        }
        return ret
    }) : !!selected ? [selected] : []
    // const options = results.map(r => ({
    //     label: r.name,
    //     value: r.id,
    //     subLabel: `${r.location.city}, ${r.location.state}`
    // }))

    return <>
    <Select
        isSearchable
        placeholder={placeholder}
        isLoading={loading || term != debouncedTerm}
        onInputChange={val => setTerm(val)}
        options={options}
        selected={!!selected ? selected.value : null}
        onChange={(val, arg2) => {
            handleChange(val)}}
        components={{
            Option: Option
        }}
        filterOption={(candidate, input) => true}
    />
    </>
}