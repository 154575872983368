import React from 'react'
import styled from "styled-components"
import { cherryRed, pinaYellow, piniWhite } from '../../constants/colors'
import { stockPini } from "../../constants/links"

const RMPButtonStyles = styled.button`
    img {
        padding-right: 10px;
        height: 20px;
        transform: rotate(0deg);
        transition: all 0.5s;
    }
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
    border: 3px solid ${cherryRed};
    :hover {
        background: ${cherryRed};
        color: white;
        img {
            transform: rotate(20deg);
        }
    }
    :disabled {
        background: #eee;
        color: black;
        img {
            transform: rotate(0deg);
        }
        border: 3px solid ${cherryRed}77;
    }
    transition: all 0.5s;
`
const RMPButton = ({children, ...props}) => {
    return <RMPButtonStyles {...props}>
        <img src={stockPini}/>
        {children}
    </RMPButtonStyles>
}
export default RMPButton