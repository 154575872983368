import React from 'react'
import MainLayout from './Layout/mainlayout'
import { Switch, Route } from 'react-router'
import LandingPage from "./LandingPage/landingpage"
import { CREATE_RATING_ROUTE } from '../constants/routes'
import CreateRating from './CreateRating/createrating'

export default function MainRouter() {
    return <MainLayout>
        <Switch>

            <Route path={CREATE_RATING_ROUTE} component={CreateRating}/>
            <Route path='' component={LandingPage}/>
        </Switch>
    </MainLayout>
}