import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export function rmpToast(message, opts) {
    toast.info(message, opts);
}

export function rmpToastError(message, opts) {
    toast.error(message, opts);
}


export default function RMPToasts(props) {
    return <ToastContainer 
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        {...props}
    />
}