import React from 'react'
import styled from 'styled-components'
import CreateRatingForm from './createratingform'

const Styles = styled.div`
    margin-top: 50px;
    margin: 0 10%;

    padding: 20px;
    margin-bottom: 100px;
    background: #FFFFFFDD;
`
export default function CreateRating() {
    return <Styles>
        {/* <h1 style={{textAlign: 'center'}}>Rate Your Pini</h1> */}
        {/* <hr></hr> */}
        <CreateRatingForm/>
    </Styles>
}