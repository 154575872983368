/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBreakdownRating = /* GraphQL */ `
  query GetBreakdownRating($id: ID!) {
    getBreakdownRating(id: $id) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      name
      value
      notes
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBreakdownRatings = /* GraphQL */ `
  query ListBreakdownRatings(
    $filter: ModelBreakdownRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreakdownRatings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ratingID
        rating {
          id
          type
          title
          yelpBusinessId
          yelpBusinessName
          restaurantName
          imgKeys
          description
          overall
          breakdownRatings {
            nextToken
          }
          createdAt
          likes {
            nextToken
          }
          comments {
            nextToken
          }
          updatedAt
          owner
        }
        name
        value
        notes
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      type
      title
      yelpBusinessId
      yelpBusinessName
      restaurantName
      imgKeys
      description
      overall
      breakdownRatings {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          name
          value
          notes
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      likes {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          ratingID
          rating {
            id
            type
            title
            yelpBusinessId
            yelpBusinessName
            restaurantName
            imgKeys
            description
            overall
            createdAt
            updatedAt
            owner
          }
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ratingID
        rating {
          id
          type
          title
          yelpBusinessId
          yelpBusinessName
          restaurantName
          imgKeys
          description
          overall
          breakdownRatings {
            nextToken
          }
          createdAt
          likes {
            nextToken
          }
          comments {
            nextToken
          }
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      ratingID
      rating {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ratingID
        rating {
          id
          type
          title
          yelpBusinessId
          yelpBusinessName
          restaurantName
          imgKeys
          description
          overall
          breakdownRatings {
            nextToken
          }
          createdAt
          likes {
            nextToken
          }
          comments {
            nextToken
          }
          updatedAt
          owner
        }
        content
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const yelpBusinessSearch = /* GraphQL */ `
  query YelpBusinessSearch($query: QueryYelpBusinessSearchQueryInput!) {
    yelpBusinessSearch(query: $query) {
      businesses {
        id
        name
        alias
        url
        price
        distance
        coordinates
        review_count
        rating
        transactions
        image_url
        categories
        display_phone
        phone
        location {
          address1
          address2
          address3
          city
          zip_code
          country
          state
          display_address
        }
        is_closed
      }
      region
      total
    }
  }
`;
export const getRatingsByDate = /* GraphQL */ `
  query GetRatingsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRatingsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRatingsByOverall = /* GraphQL */ `
  query GetRatingsByOverall(
    $type: String
    $overall: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRatingsByOverall(
      type: $type
      overall: $overall
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        likes {
          items {
            id
            ratingID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        comments {
          items {
            id
            ratingID
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
