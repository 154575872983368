import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { signOut } from '../../helpers/auth'

export default function Logout() {

    const [status, setStatus] = useState(null)
    signOut().then(() => setStatus('loggedOut'))
    if (status == 'loggedOut') {
        localStorage.removeItem('user')
        return <Redirect to='/login'/>
    }
    return <>
        <b>Logging out...</b>
    </>
}