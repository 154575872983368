import React, { useState } from "react"
import styled from "styled-components"
import { ErrorMessage, Field, Formik } from "formik"
import { Form, Button, Row, Col, Container, Collapse } from "react-bootstrap"
import * as Yup from "yup"

import RMPButton from "../rmp/RMPButton"
import YelpBusinessSearch from "./yelpbusinesssearch"
import RatingField from "../rmp/ratingfield"
import Storage from "@aws-amplify/storage"
import MultiImageUpload from "./upload"
import API from "@aws-amplify/api"
import { createBreakdownRating, createRating } from "../../graphql/mutations"
import { rmpToast, rmpToastError } from "../RMPToasts/rmptoasts"
import RMPErrorBoundary from "../rmp/RMPErrorBoundary"
import { Redirect } from "react-router"
import { gql, useApolloClient } from "@apollo/client"
import { getRatingsByDate } from "../../graphql/queries"

const Styles = styled.div`
    .row {
        margin-bottom: 5px;
    }
    label {
        font-weight: bold;
    }
    textarea {
        width: 100%;
        border: 0;
    }
    .subcategories {
        font-size: 0.9em;
    }
    input {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #ddd;
        padding: 5px;
    }
    input[name=title] {
        background: transparent;
        outline: none;
        border: 0;
        font-size: 24px;
    }
    .and-or {
        text-align: center;
        font-weight: bold;
        margin: 5px;
    }
    .error-message {
        font-size: 0.8em;
        color: red;
        text-align: center;
        font-weight: bold;
    }
`
const ratingCategories = {
    Taste: '',
    Presentation: '',
    'Serving Size': '',
    Ambience: '',
    'Strength': ''
}

export default function CreateRatingForm() {
    const client = useApolloClient()
    const [redirect, setRedirect] = useState(null)
    const [imgFiles, setImgFiles] = useState([]) // manage images outside formik
    var schema = Yup.object().shape({
        title: Yup.string(),
        type: Yup.string(),
        overall: Yup.number().min(1, 'Rate your pini!'),
        description: Yup.string(),
        yelpBusinessId: Yup.string().nullable(),
        yelpBusinessName: Yup.string().nullable(),
        restaurantName: Yup.string().nullable(),
    });
    const initialValues = {
        title: '',
        type: 'Rating', // the "primary key" (idk, amplify is weird)
        overall: -1,
        description: '',
        yelpBusinessId: null,
        yelpBusinessName: null,
        restaurantName: '',
    }
    Object.keys(ratingCategories).forEach(cat => {
        schema = schema.concat(Yup.object().shape({
            [cat]: Yup.number(),
            [`${cat}_description`]: Yup.string()
        }))
        initialValues[cat] = -1
        initialValues[`${cat}_description`] = ''
    })

    const uploadImg = async (f, uploadName) => {
        if (!uploadName) uploadName = f.name

        console.log(`Uploading ${uploadName}`)
        try {
            const ret = await Storage.put(uploadName, f, {
                contentType: "image/png",
                level: 'public'
            })
            return ret.key
        } catch (error) {
            console.log("Error uploading file: ", error);
        }   
    }

    const getDefaultName = input => {
        if (!input.title) {
            if (!!input.restaurantName && input.restaurantName.length > 0) {
                return `${input.restaurantName} Rating`
            } else {
                return `${input.yelpBusinessName} Rating`
            }
        } else {
            return input.title
        }
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log('submitting', values)
        const input = { ...values }
        
        if (!input.yelpBusinessId && !input.restaurantName) {
            rmpToastError("You must either search for a bar or supply your own description of this pini's source")
            setSubmitting(false)
            return 
        }

        // Create default name
        input.title = getDefaultName(input)

        // Only submit the sub categories we have ratings for
        const breakdownRatings = []
        Object.keys(ratingCategories).forEach(cat => {
            if (input[cat] != -1) {
                breakdownRatings.push({
                    name: cat,
                    value: input[cat],
                    notes: input[`${cat}_description`]
                })
            }
            delete input[cat]
            delete input[`${cat}_description`]
        })

        // UPLOAD IMAGES
        input['imgKeys'] = []
        for (const i in imgFiles) {
            const f = imgFiles[i]
            const key = await uploadImg(f, `${input.title}-${f.name}-${new Date()}`)
            input['imgKeys'].push(key)
        }

        // First create the rating
        const res = await API.graphql({ 
            query: createRating, 
            variables: { input }
        })
        setSubmitting(false)

        // Then create the breakdown ratings
        if (!!res.errors) {
            console.error('Error creating rating: ', res)
        }
        else if (!!res && !!res.data.createRating) {
            const newRating = res.data.createRating
            const newRatingId = newRating.id
            breakdownRatings.forEach(async function (br) {
                br['ratingID'] = newRatingId 
                console.log('creating', br)
                await API.graphql({ 
                    query: createBreakdownRating, 
                    variables: { input: br }
                }).then(console.log).catch(console.warn)
            })

        } 
        console.log('Finished submitting')
        // can't get this to work for unmounted query
        // const r = await client.refetchQueries({
        //     include: "active",
        //     onQueryUpdated(observableQuery) {
        //         console.log('here', observableQuery)
        //         switch (observableQuery.queryName) {
        //             case 'QueryIWantToRefresh':
        //                 return true;
        //         }
        //         return false;
        //     }
        // })
        setTimeout(() => { window.location.href = '/' }, 1500)
        rmpToast('Success', { autoClose: 1000, hideProgressBar: true })
    }    

    if (!!redirect) return <Redirect to={redirect}/>
    return <Styles>       
    <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={false}
    >
        {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
        <div>
            <Field name='title' 
                component='input'
                placeholder='Title your rating'
            />
            <div>
                <small style={{visibility: values.title.length == 0 ? 'visible' : 'hidden'}}>
                    Or we'll title it for you: "{getDefaultName(values)}"
                </small>
            </div>
        </div>
        <hr></hr>

        <Row noGutters>
            <Col>
            <RMPErrorBoundary>
                <YelpBusinessSearch 
                    placeholder='Search for a bar...'
                    setYelpBusinessId={id => handleChange({ target: { value: id, name: 'yelpBusinessId' }})}
                    setYelpBusinessName={name => handleChange({ target: { value: name, name: 'yelpBusinessName' }})}
                />
            </RMPErrorBoundary>
            </Col>
            <Col sm='auto' className='and-or'>
                And/Or
            </Col>
            <Col>
                <Field name='restaurantName' 
                    component='input'
                    placeholder="Enter a bar's name"
                />
            </Col>
        </Row>
        <div>
            <MultiImageUpload
                imgFiles={imgFiles}
                setImgFiles={setImgFiles}
            />
        </div>
        <Form.Group>
            <Row>
                <Col sm={3}>
                    <label>How was your pini?</label>
                </Col>
                <Col className='m-auto' sm='auto'>
                    <Field name='overall' as={RatingField}/>
                    <div className='error-message'>
                        <ErrorMessage name='overall'/>
                    </div>
                </Col>
                <Col sm={3}></Col>
            </Row>
            <Collapse in={values.overall > 0}>
                <Field
                    name='description'
                    component='textarea' 
                    placeholder="Care to elaborate?"
                />
            </Collapse>
        </Form.Group>

        <hr></hr>
        <div style={{ marginBottom: '5px' }}>
            <em>Optional...</em>
        </div>
        <Container className='subcategories'>
            {Object.keys(ratingCategories).map(cat => <Form.Group key={cat}>
                <Row>
                    <Col sm={3}>
                        <label>{cat}</label>
                    </Col>
                    <Col className='m-auto' sm='auto'>
                        <Field name={cat} as={RatingField}/>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
                <Collapse in={values[cat] > 0}>
                    <Field
                        name={`${cat}_description`}
                        component='textarea' 
                        placeholder="Notes..."
                    />
                </Collapse>
            </Form.Group>)}
        </Container>
        <center>
            <RMPButton type="submit">
                {isSubmitting ?
                'Submitting...'
                : 'Submit'}
            </RMPButton>
        </center>
            
        </Form>
        )}
    </Formik>
    </Styles>
}
