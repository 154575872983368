import * as React from "react"
import { Helmet } from "react-helmet"
import { stockPini } from "../../constants/links"
import 'bootstrap/dist/css/bootstrap.min.css'

export default function DocumentHead({ title }) {
    return <Helmet>
        <title>{ title | 'Rate My Pini' }</title>

        <meta name="robots" content="noindex"/>
        <meta name="googlebot" content="noindex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />

        {/* Favicon */}

        {/* Bootstrap */}
        <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
            integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
            crossorigin="anonymous"
        />

        <style type="text/css">{`
            html { 
                height: 100%;
                width: 100%;
            }
            body { 
                background-color: #fefefe; 
                height: 100%;
                width: 100%;
            }
            #root {
                height: 100%;
                width: 100%;
            }
        `}</style>
    </Helmet>
}
