import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useUser } from '../../helpers/user'

const PrivateRoute = ({component: Component, render, ...rest}) => {
    const { userLoading, isLoggedIn } = useUser()
    if (userLoading) return null
    const valid = isLoggedIn
    if (!valid) {
        localStorage.setItem('loginRedirect', window.location.pathname + window.location.search)
    }
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route {...rest} render={props => (
            valid ?
                !!Component ? <Component {...props} />
                : render()
            : <Redirect to={'/login'} />
        )} />
    );
};

export default PrivateRoute;