import React, { useEffect, useState } from 'react'
import Storage from '@aws-amplify/storage'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

const Styles = styled.div`
    position: relative;
    height: 100px;
    width: 100px; /*Use this to control width of the parent container, hence the image*/
    overflow: hidden;

    cursor: pointer;

    border-radius: 50%;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;

    }
`
const defaultSrc = process.env.PUBLIC_URL +'/defaultuser.jpg'
export default function ProfPic({ username, height='50px', width='50px' }) {
    const [show, setShow] = useState(false)
    const [src, setSrc] = useState(defaultSrc)
    const key = `profilepictures/${username}`
    useEffect(() => {
        Storage.get(key, { level: 'public', expires: 604800 }).then(setSrc)
    }, [])

    const addDefaultSrc = ev => {
        ev.target.src = defaultSrc
    }

    return <>
    <Styles onClick={e => {
        e.stopPropagation()
        setShow(true)
    }} style={{height, width}}>
        <img src={src} 
            onError={addDefaultSrc} 
            alt="" 
        />
    </Styles>
    <Modal size='lg' show={show} onHide={e => {
        setShow(false)
    }}>
        <Modal.Header closeButton>
            <h2>{username}</h2>
        </Modal.Header>
        <Modal.Body>
            <img src={src} 
                onError={addDefaultSrc} 
                alt="" 
                width='100%'
            />
        </Modal.Body>
    </Modal>
    </>
}