export const listRatingsBreakdown = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        createdAt
        updatedAt
        owner
        breakdownRatings {
            id
            ratingID
            name
            value
            notes
        }
      }
      nextToken
    }
  }
`;

export const yelpBusinessSearchWithLocation = /* GraphQL */ `
  query YelpBusinessSearch($query: QueryYelpBusinessSearchQueryInput!) {
    yelpBusinessSearch(query: $query) {
      businesses {
        id
        name
        alias
        url
        price
        distance
        coordinates
        review_count
        rating
        transactions
        image_url
        categories
        display_phone
        phone
        is_closed
        location {
            address1
            address2
            address3
            city
            zip_code
            country
            state
            display_address
        }
      }
      region
      total
    }
  }
`;

export const getRatingsByDateBreakdown = /* GraphQL */ `
  query GetRatingsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRatingsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        yelpBusinessId
        yelpBusinessName
        restaurantName
        imgKeys
        description
        overall
        createdAt
        updatedAt
        owner
        breakdownRatings {
          items {
            id
            ratingID
            name
            value
            notes
            createdAt
            updatedAt
            owner
          }
        }
      }
      nextToken
    }
  }
`;
