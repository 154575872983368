import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import styled from "styled-components";
import { stockPini } from "../../constants/links";
import { useUser } from "../../helpers/user";

const Styles = styled.div`
    color: white !important;
`
export default function Header() {
    const { user } = useUser()
    if (!user) return null

    return <Styles>
    <Navbar bg="dark" variant='dark' expand="sm">
        <Navbar.Brand href="/">
            <img height='30px' src={stockPini}/>
            {' '}
            Rate My Pini
        </Navbar.Brand>
            
        <Nav className="ml-auto"> 
            <NavDropdown 
                alignRight
                title={user.username} 
            >
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    </Navbar>
    </Styles>
}