import { useEffect, useState } from "react";
import { faCamera, faImages, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { rmpToastError } from "../RMPToasts/rmptoasts";

const Styles = styled.div`
    text-align: center;
    input[type=file] { 
        display: none;
        visibility: hidden;
        position: absolute
    }
    .or {
        text-align: center;
    }
`

const ImgWrap = styled.div`
    position: relative;
    display: inline-block;
    .x-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        transition: all 0.5s;
        :hover {
            font-size: 1.1em;
            color: red;
        }
    }
`

const MAX_FILES = 5

export default function MultiImageUpload({ 
    imgFiles,
    setImgFiles
}) {
    // we keep track of uploaded images here, but parent will need its own list fed by "onChange"
    // const [imgFiles, setImgFiles] = useState([])

    const [imgUrls, setImgUrls] = useState([]) // each entry is an obj {name, url}

    const handleChange = e => {
        const errs = [] 
        const files = Array.from(e.target.files)

        if (files.length > MAX_FILES) {
            const msg = `Only ${MAX_FILES} images can be uploaded at a time`
            alert(msg)
            return
        }
        const validFiles = []
        const types = ['image/png', 'image/jpeg', 'image/gif']

        files.forEach((file, i) => {
            if (types.every(type => file.type !== type)) {
                errs.push(`'${file.type}' is not a supported format`)
                return
            }

            if (file.size > 10000000) { // 10MB
                errs.push(`'${file.name}' is too large, please pick a smaller file`)
                return
            }

            validFiles.push(file)
        })
        setImgFiles(validFiles)

        if (errs.length) {
            return errs.forEach(err => rmpToastError(err))
        }
    }

    const handleRemoveImg = name => {
        const newImgFiles = imgFiles.filter(f => f.name != name)
        setImgFiles(newImgFiles)
    }

    useEffect(() => {
        setImgUrls(imgFiles.map(f => ({ name: f.name, url: URL.createObjectURL(f) })))
        // free memory when ever this component is unmounted
        return () => imgUrls.forEach(u => URL.revokeObjectURL(u.url))
    }, [imgFiles])

    return <Styles>
    <div className='image-bay'>
        {imgUrls.map(u => <ImgWrap key={u.name}>
            <FontAwesomeIcon className='x-icon' icon={faTimes} 
                onClick={() => handleRemoveImg(u.name)}
            />
            <img src={u.url} height='100px' />
        </ImgWrap>)}
    </div>
    <label htmlFor='multi' style={{cursor: 'pointer', fontSize: '1.2em'}}>
        <FontAwesomeIcon icon={faCamera} color='#6d84b4'/>
        {' '}
        Upload{isMobile && '/Capture'} Images
    </label>
    <input type='file' id='multi' onChange={ev => handleChange(ev)} multiple />
    </Styles>
}