import * as React from "react"
// import { isMobile } from "react-device-detect"
import DocumentHead from "./documenthead"
import styled from 'styled-components'
import { stockImageBeach } from "../../constants/links"
import Header from "./header"
import { cherryRed, piniWhite } from "../../constants/colors"
import RMPToasts from "../RMPToasts/rmptoasts"

const Styles = styled.div`
    position: relative;
    min-height: 100%;  
    width: 100%;
    z-index: 0;
    top: 20;
    background: linear-gradient(to top right, ${cherryRed}, ${piniWhite});
    padding-bottom: 50px;


    .fixed-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        color: white;
        text-align: center;
    }
`
function MainLayout ({ children }) {

    return <Styles>
        <DocumentHead/>
        <Header/>
        {children}
        <div className='fixed-footer'>
            <a style={{ fontSize: '10px', position: 'fixed', bottom: 0, left: '10px' }}>
                Icons by Flaticon
            </a>
        </div>
        <RMPToasts/>
    </Styles>
}

export default MainLayout