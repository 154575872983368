import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify';
import { updateUser } from './auth';

// export async function isLoggedIn() {
//     const user = await getUser()

//     return !!user
// }


export function useUser() {
    const [state, setState] = useState({ userLoading: true, isLoggedIn: undefined, user: undefined, error: undefined });

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {
            setState({ userLoading: false, isLoggedIn: true, user })
        }).catch(err => {
            setState({ userLoading: false, isLoggedIn: false, user: undefined, error: err })
        })
    }, [])
    return state;
}

export function userIsAdmin(user) {
    const groups = user.signInUserSession.accessToken.payload['cognito:groups']
    if (!groups) return false
    return groups.includes('Admin')
}


export function setProfilePictureUrl(user) {
    if (!('picture' in user.attributes)) {
        try {
            updateUser({ picture: user.username })
        } catch {
            console.warn('Error setting user picture')
        }
    }
}