import React, { useEffect, useRef, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { deleteRating, createLike, createComment, deleteLike } from '../../graphql/mutations'
import { Modal, Carousel, Row, Col, Collapse } from 'react-bootstrap'
import { cherryRed } from '../../constants/colors'
import CoconutIcon from '../rmp/CoconutIcon'
import styled from 'styled-components'
import Storage from '@aws-amplify/storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faComment, faHeart, faTrash } from '@fortawesome/free-solid-svg-icons'

import { timeSince } from "../../helpers/dates"
import { userIsAdmin, useUser } from '../../helpers/user'
import { rmpToastError } from '../RMPToasts/rmptoasts'
import ProfPic from './profpic'
import { isMobile } from 'react-device-detect'
import RMPButton from '../rmp/RMPButton'

const Styles = styled.div`
    overflow: hidden; // for large images
    padding: 0 5%;
    margin: 0;
    border-bottom: 1px solid #aaa;
    :hover {
        box-shadow: inset 0 0 10px ${cherryRed};
    }
    transition: all 0.5s;

    .title {
        font-size: 1.2em;
        font-weight: bold;
    }
    .footer {
        margin-top: 5px;
        text-align: center;
        font-size: 1em;
        font-weight: bold;
        color: #ddd;
    }
    p.description {
        overflow-wrap: break-word;
    }
    .icon {
        cursor: pointer;
        color: #ccc;
        :hover {
            color: #cc0000;
        }
        transition: all 0.2s;
    }
    .liked {
        color: #cc0000;
    }
    .comment {
        :hover {
            color: #0000cc;
        }
    }
`
export default function RatingFeedRating({ rating, expand, setExpand }) {
    const { user } = useUser()
    const commentRef = useRef(null)
    const [deleteThisRating] = useMutation(gql(deleteRating), {
        refetchQueries: ['GetRatingsByDate']
    })
    const [addLike] = useMutation(gql(createLike), {})
    const [removeLike] = useMutation(gql(deleteLike), {
        update(cache, { data: { createLike }}) {
            // cache.modify({
            //     fields: {
            //         Like(existing, { readField }) {
            //             console.log(existing)
            //             return existing
            //         },
            //         Rating(existing, { readField }) {
            //             console.log(existing)
            //             return existing
            //         },
            //         Ratings(existing, { readField }) {
            //             console.log(existing)
            //             return existing
            //         },
            //         getRatingsByDate(existing, { readField }) {
            //             console.log(existing)
            //             return existing
            //         },
            //     },
            // });        
        }
    })

    if (!user) return null
    const isAdmin = userIsAdmin(user)
    const canDelete = isAdmin || rating.owner == user.username

    const handleDelete = () => {
        if (!canDelete) {
            rmpToastError('Not authorized to delete')
        }
        if (window.confirm('Are you sure you want to delete this rating?')) {
            deleteThisRating({ variables: { input: { id: rating.id }}})
        }
    }


    const likes = rating.likes.items
    const userLikes = likes.filter(l => l.owner == user.username)    
    const isLiked = userLikes.length > 0
    const handleToggleLike = () => {
        if (isLiked) {
            userLikes.forEach(l => {
                removeLike({ variables: { input: { id: l.id }}})
            })
        } else {
            addLike({ variables: { input: { ratingID: rating.id }}})
        }
    }


    const comments = rating.comments.items

    return <Styles onClick={() => setExpand(true)}>
        <Row className='header'>
            <Col xs='auto'>
                <table>
                <tbody>
                <tr>
                    <td><ProfPic username={rating.owner}/></td>
                    <td style={{paddingLeft: '10px'}}>
                        <small>
                            {rating.owner} • {timeSince(rating.createdAt)} ago
                        </small>
                    </td>
                </tr>
                </tbody>
                </table>
            </Col>
        </Row>
        <Row noGutters >
            <Col xs style={{textAlign: 'center'}}>
                <Row>
                    <Col>
                        <div className='title'>{rating.title}</div>
                        <Nuts nuts={rating.overall}/>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <p className='description'>{!!rating.description ? `"${rating.description}"` : <em>No description</em>}</p>
                        <PiniImgs imgKeys={rating.imgKeys}/>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Collapse in={expand}>
            <div>
                <ExpandedRFR rating={rating} commentRef={commentRef}/>
            </div>
        </Collapse>

        <Row className='footer' onClick={e => e.stopPropagation()}>
            <Col xs='3'>
                {expand && <div style={{float: 'right'}}>
                    <a href="" 
                    style={{fontSize: '0.8em'}}
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setExpand(false)
                    }}>
                        Minimize
                    </a>
                </div>}
            </Col>
            <Col style={{textAlign: 'center'}}>
                <span style={{marginRight: '10px'}}>
                    {comments.length}
                    {' '}
                    <FontAwesomeIcon icon={faComment} className={`icon comment`} 
                        onClick={e => {
                            setExpand(true)
                            setTimeout(() => commentRef.current.focus(), 500)
                        }}
                    />
                </span>
                <span>
                    {likes.length}
                    <FontAwesomeIcon icon={faHeart} className={`icon ${isLiked ? 'liked' : ''}`} 
                        onClick={e => {
                            handleToggleLike()
                            e.stopPropagation()
                        }}
                    />
                </span>
            </Col>
            <Col xs='3' className='ml-auto' >
                {canDelete && <FontAwesomeIcon 
                    className='icon'
                    icon={faTrash} 
                    onClick={handleDelete}
                />}
            </Col>
        </Row>
    </Styles>

}


const Nuts = ({ nuts, outOf=5, showRange=true, height, highlight=cherryRed }) => {
    if (showRange) {
        const n = [...Array(outOf)]
        return n.map((_, i) => 
            <CoconutIcon key={i} 
                circleHighlight={i<nuts ? highlight : null} 
                height={height} 
                color={i < nuts ? 'white' : 'black'}
            />)
    } else {
        const n = [...Array(nuts)]
        return n.map((_, i) => <CoconutIcon key={i} circleHighlight={highlight} height={height} color='white'/>)
    }
}


const ImgStyles = styled.div`
    cursor: pointer;
    text-align: center;
    .container {
        padding: 0;        
        border-radius: 10px;
        // border: 1px solid #333;

        display: inline-block;
        position: relative;
        text-align: center;
        color: #ddd;
        max-height: 300px;
        max-width: ${props => props.maxWidth ? props.maxWidth : '250px'};
        overflow: hidden;
    }
      
    .bottom-right {
        position: absolute;
        bottom: 5px;
        right: 20px;
        font-size: 40px;
        font-style: italic;

        color: white;
        background: ${cherryRed+'77'};
        border-radius: 40%;
      }

    .pimg {

        width: 100%;
        height: 100%;

        :hover {
            opacity: 0.8;
        }
    }
`
const ModalStyles = styled.div`
    background: #333;
    .carousel-control {
        color: ${cherryRed};
        font-size: 60px;
    }
`
const PiniImgs = ({
    imgKeys
}) => {
    // console.log(imgKeys)
    const [show, setShow] = useState(false)
    const [imSrcs, setImSrcs] = useState([]) // list of objects { key, url }
    useEffect(async () => {
        if (imgKeys.length > 0) {
            const newSrcs = []
            for (let i=0; i<imgKeys.length; i++) {
                const k = imgKeys[i]
                const url = await Storage.get(k, { level: 'public', expires: 604800 })
                newSrcs.push({ key: k, url })
            }
            setImSrcs(newSrcs)
        }
    }, [imgKeys])
    if (imSrcs.length == 0) return <></>
    
    var numToShow = isMobile ? 1 : 2
    const imToShow = imSrcs.slice(0, numToShow)

    // default max width = 80%, but divide by numtoshow
    const maxWidth = `${80/numToShow}%`
    return <>
        <ImgStyles maxWidth={maxWidth}>
            <div>
                {imToShow.map(i => <div className='container' key={i.url}>
                    <center>
                    <img className="pimg" onClick={e => {
                            e.stopPropagation()
                            setShow(true)
                        }}
                        src={i.url}/>
                    </center>
                    {imSrcs.length > numToShow && <div className='bottom-right'>
                        +{imSrcs.length - numToShow}
                    </div>}
                </div>)}
            </div>
        </ImgStyles>
        <Modal size='lg' show={show} onHide={() => setShow(false)} onClick={e => e.stopPropagation()}>
            <ModalStyles>
            <Carousel
                prevIcon={<FontAwesomeIcon className='carousel-control left' icon={faCaretLeft}/>}
                nextIcon={<FontAwesomeIcon className='carousel-control right' icon={faCaretRight}/>}
            >
            {imSrcs.map(src => <Carousel.Item key={src.key}>
                <img
                    src={src.url}
                    alt="Pini image"
                    width='100%'
                />
            </Carousel.Item>)}
            </Carousel>
            </ModalStyles>
        </Modal>
    </>
}


const BRStyles = styled.div`
    margin-top: 10px;
    font-size: 0.9em;
    table.breakdowns {
        text-align: center;
        tr {
            border-bottom: 1px solid #ddd;
        }
        th {
            padding: 0 10px;
        }
        td {
            padding: 0 10px;
        }
    }
    table.comment-header {
        width: 100%;
    }
    .comments-container {
        width: 95%;
        margin: auto;
    }
    .inner-comments {
        width: 95%;
        margin: auto;
    }
    .comment-box {
        border: 1px solid #ddd;
        padding: 5px;
    }
    textarea.comment-input {
        margin-top: 10px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #ddd;
        padding: 2px 5px;
        transition: all 0.5s;
    }
`
const ExpandedRFR = ({ rating, commentRef }) => {
    const [show, setShow] = useState(false)
    const [content, setContent] = useState('')
    const likedBy = rating.likes.items.map(l => l.owner)
    const likesToShow = 3
    var comments = [...rating.comments.items]
    comments.sort((a,b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) return -1
        return 1
    })
    const [addComment] = useMutation(gql(createComment), {
        onCompleted: args => {
            setContent('')
        }
    })

    const handleSubmitComment = e => {
        e.preventDefault()
        const content = e.target.comment.value
        if (!content.length) return
        addComment({ variables: { input: { ratingID: rating.id, content } }})
    }

    return <BRStyles>
    <center>
    <table className='breakdowns'>
    <tbody>
        {rating.breakdownRatings.items.map(br => <tr key={br.name}>
            <th>{br.name}</th>
            <td>
                <Nuts nuts={br.value} height='25px' highlight={cherryRed+'aa'}/>
                {!!br.notes && <div className='notes'>"{br.notes}"</div>}
            </td>
        </tr>)}
    </tbody>
    </table>

    <br></br>
    {likedBy.length > 0 ? <>
        Liked by {likedBy.slice(0, likesToShow).join(', ')}
        {' '} 
        <a href='' onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setShow(true)              
        }}>
            {likedBy.length > likesToShow ? <>
                + {likedBy.length - likesToShow} more 
            </>: <>See all</>}
        </a> 
    </> : <em>No likes</em>}
    </center>
    <div className='comments-container'>
        <b>Comments</b>
        <div className='inner-comments'>
            {comments.length == 0 && <div><em>No comments</em></div>}
            {comments.map(c => <div key={c.id} className='comment-box'>
                <table className='comment-header'>
                <tbody>
                <tr>
                    <td><ProfPic username={c.owner} height='30px' width='30px'/></td>
                    <td style={{paddingLeft: '10px'}}>
                        <small>
                            {c.owner} • {timeSince(c.createdAt)} ago
                        </small>
                    </td>
                </tr>
                </tbody>
                </table>
                {c.content}
            </div>)}
        </div>
        <form onSubmit={handleSubmitComment}>
            <textarea className='comment-input'
                ref={commentRef}
                name='comment'
                placeholder='Add comment...'
                rows={2}
                onClick={e => {
                    e.stopPropagation()
                }}
                value={content}
                onChange={e => setContent(e.target.value)}
            />
            <center>
                <RMPButton type='submit' disabled={content.length == 0} onClick={e => e.stopPropagation()}>
                    Submit
                </RMPButton>
            </center>
        </form>
    </div>
    <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            {rating.title} Likes
        </Modal.Header>
        <Modal.Body>
            <div>
                {likedBy.map(l => <div key={l}>
                    <table><tbody>
                        <tr>
                            <td><ProfPic username={l} /></td>
                            <td>{l}</td>
                        </tr>
                    </tbody></table>
                </div>)}
            </div>
        </Modal.Body>
    </Modal>
    </BRStyles>
}