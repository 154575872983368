import React from "react"

export default function LinkButton({ sm, black, style, onClick, children, ...props }) {
    var linkStyle = {}
    if (sm) linkStyle.fontSize = '0.8em'
    if (black) linkStyle.color = 'black'
    linkStyle = {...linkStyle, ...style}
    return <a 
        href=""
        {...props}
        style={linkStyle}
        onClick={e => {
            e.preventDefault()
            if (onClick) onClick(e)
        }}
    >
        {children}
    </a>
}