import React from 'react'
// import { gql, useQuery } from '@apollo/client'
import RMPButton from '../rmp/RMPButton'
import { Link } from "react-router-dom"
import { CREATE_RATING_ROUTE } from '../../constants/routes'
import styled from 'styled-components';
import RatingFeed from '../Rating/ratingfeed';
import ProfPic from '../Rating/profpic';
import { useUser } from '../../helpers/user';
import { Row, Col } from 'react-bootstrap';


const Styles = styled.div`
    margin: 3% 5%;
    padding: 10px 0;

    border-radius: 5px;
    background: #ffffff;
`

export default function LandingPage({}) {
    const { user } = useUser()
    return <Styles>
        <Row>
            <Col>
                {!!user && <ProfPic username={user.username} height='100px' width='100px'/>}
            </Col>
            <Col xs='auto'>
                <Link to={CREATE_RATING_ROUTE}>
                    <RMPButton>Rate New Pini</RMPButton>
                </Link>
            </Col>
            <Col></Col>
        </Row>
        <hr></hr>
        <RatingFeed/>
    </Styles>
}