import { faBlender } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'formik';
import React, { useState } from 'react'
import styled from 'styled-components';
import { cherryRed, piniWhite } from '../../constants/colors';
import CoconutIcon from './CoconutIcon';

const Styles = styled.table`
    // margin: auto;
    cursor: pointer;
`

const IconStyles = styled.span`
    border-radius: 50%;
    padding: 5px;
    margin: 5px;

    color: black;
    text-align: center;

    cursor: pointer;
    font-size: 20px;
`

const SelectableIcon = ({ selected, ...props }) => 
    <IconStyles {...props}>
        <CoconutIcon 
            height='40px'
            color={selected ? 'white' : 'black'}
            circleHighlight={selected ? cherryRed : null}
        />
    </IconStyles>


export function NumberRating({ value, handleChange, min=1, max=5 }) {
    // const [value, setValue] = useState(null)
    const [tempValue, setTempValue] = useState(null)
    const values = []
    for (let i=min; i<=max; i++) { values.push(i) }

    const isSelected = val => {
        if (!!tempValue) {
            return tempValue >= val
        } else if (!!value) {
            return value >= val
        }
        return false
    }

    return <Styles>
        <tbody>
        <tr>
        {values.map(val => <td key={val}
            onClick={() => {
                if (val == value) {
                    // clicking the current value unselects
                    handleChange(undefined)
                } else {
                    handleChange(val)
                }
            }}
            onMouseEnter={() => setTempValue(val)}
            onMouseLeave={() => setTempValue(null)}                
        >
            <SelectableIcon
                selected={isSelected(val)} 
            />
        </td>)}
        </tr>
        </tbody>
    </Styles>
}

export default function RatingField({
    name,
    ...rest
}) {
    if (!name) throw Error('"name" required')
    return <Field name={name} id={name} type="number">
        {({ field: { value }, form: { setFieldValue } }) => (
        <div>
            <NumberRating
                value={value}
                handleChange={val => {
                    setFieldValue(name, val)
                }}
                {...rest}
            />
        </div>
        )}
    </Field>
}