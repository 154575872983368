import React from "react"
import { ReactComponent as Coconut } from "../../assets/coconut.svg"


export default function CoconutIcon({
    height='30px',
    color,
    styles,
    circleHighlight // color to highlight with in circle
}) {
    var customStyles = {
        borderRadius: '50%',
        padding: '5px',
        transition: 'all 0.5s',
        ...styles
    }
    if (!!circleHighlight) {
        customStyles = {
            ...customStyles,
            background: circleHighlight,
        }
    }
    return <Coconut 
        height={height} 
        color={color} 
        style={customStyles}
    />
}