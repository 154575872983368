import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form as FormikForm } from "formik"
import { Form } from "react-bootstrap"
import * as Yup from "yup"
import { Auth } from 'aws-amplify';

import { stockImageBeach } from "../../constants/links"
import RMPButton from "../rmp/RMPButton"
import DocumentHead from "../Layout/documenthead"
import LinkButton from "../rmp/LinkButton"
import { signUp } from "../../helpers/auth"
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router"
import { setProfilePictureUrl } from "../../helpers/user"

export const BeachStyles = styled.div`
    width: 100vw;
    height: 100vh;
    background: center no-repeat url(${stockImageBeach});
    padding: 5%;

    .container {
        margin-top: 20%;
        background: #ffffffdf;
        padding: 20px;
        text-align: center;
        min-width: 200px;
        min-height: 200px;
        max-width: 400px;
        box-shadow: 0 0 5px #ddd;
    }

    input {
        margin: 10px;
    }    
`
export default function Login() {
    const history = useHistory()
    const { url: baseRoute, ...rest } = useRouteMatch()

    return <BeachStyles>
        <DocumentHead/>
        <div className='container'>
            <LinkButton sm black
                style={{float: 'left'}}
                onClick={() => history.push(baseRoute)}
            >
                No login? Sorry, RMP is invite-only for now.
            </LinkButton>
            <br></br>
            <Switch>
                <Route path={baseRoute}><SignInForm/></Route>
            </Switch>
        </div>
        
    </BeachStyles>
}


const SignInForm = ({ }) => {
    const [newUser, setNewUser] = useState(false)
    const [error, setError] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const schema = {
        username: Yup.string().required('Username is required'),
        password: Yup.string().required("Password is required"),
    }
    if (newUser) {
        schema['newPassword'] = Yup.string().required('New Password is required')
        schema['email'] = Yup.string().email().required('Email is required')
    }
    const loginSchema = Yup.object().shape(schema);
    const initialValues = { username: "", password: "", email: '', newPassword: "" }

    const handleSubmit = (values, { setSubmitting }) => {
        Auth.signIn(values.username, values.password).then(user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                if (!values.newPassword) {
                    setNewUser(true)
                    setSubmitting(false)
                    return
                }
                Auth.completeNewPassword(
                    user,               // the Cognito User Object
                    values.newPassword,       // the new password
                    {
                        email: values.email,
                    }
                ).then(user => {
                    // at this time the user is logged in if no MFA required
                    setRedirect('/')
                    setSubmitting(false)
                }).catch(e => {
                    console.error(e);
                    setSubmitting(false)
                });
            } else {
                // unnecessary
                // setProfilePictureUrl(user)
                setRedirect('/')
            }
        }).catch(err => {
            console.error("Error signing in:", err)
            setError(err)
            setSubmitting(false)
        })
    }
    if (!!redirect) return <Redirect to={redirect}/>
    return <>
        <h3></h3>
        <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
        >
            {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
            <FormikForm>
            {!!error && <>
                <span style={{ color: 'darkred', fontSize: '0.8em' }}>
                    {error.message}
                </span>
            </>}
            <Form.Group>
                <Form.Control 
                    name="username"
                    type="username" 
                    placeholder="Enter username"
                    value={values.username}
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.username}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Control 
                    name="password"
                    type="password" 
                    placeholder="Password" 
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.password}
                </Form.Control.Feedback>
                {/* <LinkButton sm>
                    Forgot Password?
                </LinkButton> */}
            </Form.Group>
            {!!newUser && <>
                <em>
                    <div>Hi First time user!</div>
                    {/* <div>Enter your email and set a new password for yourself</div> */}
                </em>
                <Form.Group>
                <Form.Control 
                    name="email"
                    type="email" 
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email}
                </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                <Form.Control 
                    name="newPassword"
                    type="password" 
                    placeholder="New Password" 
                    value={values.newPassword}
                    onChange={handleChange}
                    isInvalid={!!errors.newPassword}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                </Form.Control.Feedback>
                </Form.Group>
            </>}
            <RMPButton type="submit">
                {isSubmitting ?
                'Logging in...'
                : 'Login'}
            </RMPButton>
            </FormikForm>
            )}
        </Formik>
    </>
}

const registerSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email address').required('Email is required'),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().required('Passwords must match')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
const SignUpForm = () => {
    const handleSubmit = values => {
        console.log('submitting', values)
        signUp(values.email, values.username, values.password)
    }
    return <>
        <h3></h3>
        <Formik
            initialValues={{ email: '', username: "", password: "", confirmPassword: '' }}
            validationSchema={registerSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
        >
            {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Control 
                    name="username"
                    type="username" 
                    placeholder="Enter username"
                    value={values.username}
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.username}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Control 
                    name="email"
                    type="email" 
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Control 
                    name="password"
                    type="password" 
                    placeholder="Password" 
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.password}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Control 
                    name="confirmPassword"
                    type="password" 
                    placeholder="Confirm Password" 
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                </Form.Control.Feedback>
            </Form.Group>
            <RMPButton type="submit">
                {isSubmitting ?
                'Submitting...'
                : 'Submit'}
            </RMPButton>
            </Form>
            )}
        </Formik>
    </>
}